import { installationOrderService, minioService } from '@/api';
import { InstallationOrderStatusEnum } from '@/resource/enum';
import { InstallationOrderList } from '@/resource/model';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message, MessageBox, Tree } from 'element-ui';
import { MessageBoxData, MessageBoxInputData } from 'element-ui/types/message-box';
import { Component, Vue } from 'vue-property-decorator';
import InstallContent from './install-content/install-content.vue';
// import InstallAttachment from './install-attachment/install-attachment.vue';

interface Node {
  id: string;
  level: number;
  label: string;
  children: Array<Node> | undefined;
  valueList: Array<InstallationOrderList>;
}
@Component({
  name: 'AddInstallationOrder',
  components: { InstallContent }
})
export default class InstallationAcceptance extends Vue {
  public installationOrder: InstallationOrderList = {} as InstallationOrderList;

  public rejectDialogVisible: boolean = true;

  // 城市平铺数组的下标
  public index: number = 0;

  public shopCityTree: Array<Node> = [];
  public indexList: Array<string | number> = [];

  public currentlySelectedNode: string | number = '';
  public activeOption: string | number = '';
  public async created(): Promise<void> {
    this.currentlySelectedNode = '';
    this.loadDetails();
  }
  public async activated(): Promise<void> {
    await minioService.init();
    this.loadDetails();
  }
  /**
   * 审批通过
   */
  public approved(): void {
    showWarningConfirm(translation('tip.confirmApprovalSuccess'))
      .then(async () => {
        try {
          await installationOrderService.approved(this.installationOrder.id);
          this.installationOrder!.status = InstallationOrderStatusEnum.completed;
          Message.success(translation('operationRes.operationSuccess'));
          this.next();
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 驳回
   */

  public openReject(): void {
    this.rejectDialogVisible = true;
  }
  public async reject(): Promise<void> {
    MessageBox.prompt(translation('installationOrder.auditDescription'), {
      inputPlaceholder: translation('installationOrder.inputAuditDescription'),
      inputValidator: (value: string): boolean => {
        return !!value;
      },
      inputErrorMessage: translation('installationOrder.inputAuditDescription')
    })
      .then(async (data: MessageBoxData) => {
        try {
          await installationOrderService.reject({
            id: this.installationOrder.id,
            reason: (data as MessageBoxInputData).value
          });
          this.installationOrder!.status = InstallationOrderStatusEnum.inService;
          Message.success(translation('operationRes.operationSuccess'));
          this.next();
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  public handleNodeClick(nodeData: any): void {
    if ([1, 2].includes(nodeData.level)) {
      return;
    }
    this.installationOrder = nodeData.valueList[0];
    this.currentlySelectedNode = nodeData.id;
  }

  /**
   * 生成树形结构数据
   * @param shopList
   * @returns
   */
  public handleTreeData(
    shopList: Array<InstallationOrderList>
  ): {
    treeList: Array<Node>;
    indexList: Array<string | number>;
  } {
    const treeList: Array<Node> = [];
    const indexList: Array<string | number> = [];
    // 去重
    const cacheList = Array.from(new Set(shopList.map(x => x.customerName)));
    cacheList.forEach((item, index) => {
      const node: Node = {
        id: `${item}${index}`,
        label: '',
        level: 1,
        children: [],
        valueList: []
      };
      shopList.forEach(cur => {
        if (cur.customerName === item) {
          node.valueList.push(cur as InstallationOrderList);
          const node3: Node = {
            id: `${cur.shopName}${cur.id}`,
            label: cur.shopName,
            level: 3,
            children: undefined,
            valueList: [cur]
          };
          node.children!.push(node3 as Node);
          indexList.push(node3.id);
        }
      });
      node.label = `${item}(${node.valueList.length})`;

      treeList.push(node);
    });
    // treeList.forEach(i => {
    //   const cityList = Array.from(new Set(i.valueList.map((x: InstallationOrderList) => x.projectName)));
    //   cityList.forEach((item, index) => {
    //     const node: Node = {
    //       id: `${item}${index}`,
    //       label: '',
    //       level: 2,
    //       children: [],
    //       valueList: []
    //     };
    //     shopList.forEach(cur => {
    //       if (cur.projectName === item) {
    //         node.valueList.push(cur as InstallationOrderList);
    //         const node3: Node = {
    //           id: `${cur.shopName}${cur.id}`,
    //           label: cur.shopName,
    //           level: 3,
    //           children: undefined,
    //           valueList: [cur]
    //         };
    //         node.children!.push(node3 as Node);
    //         indexList.push(node3.id);
    //       }
    //     });
    //     node.label = `${item}(${node.valueList.length})`;

    //     i.children!.push(node);
    //   });
    // });
    return { treeList, indexList };
  }
  public next(): void {
    let nextIndex = this.indexList.indexOf(this.currentlySelectedNode) + 1;
    if (nextIndex === this.indexList.length) {
      nextIndex = 0;
    }
    this.currentlySelectedNode = this.indexList[nextIndex];
    this.loadDetails();
    // this.selected(nodeKey);
    // const node = (this.$refs.installationOrderTree as Tree).getNode(nodeKey);
    // this.handleNodeClick(node.data);
  }
  public back(): void {
    let nextIndex = this.indexList.indexOf(this.currentlySelectedNode) - 1;
    if (nextIndex < 0) {
      nextIndex = this.indexList.length - 1;
    }
    this.currentlySelectedNode = this.indexList[nextIndex];
    this.loadDetails();
    // this.selected(nodeKey);
    // const node = (this.$refs.installationOrderTree as Tree).getNode(nodeKey);
    // this.handleNodeClick(node.data);
  }

  public selected(val: string | number): void {
    val && (this.activeOption = val);
    val && (this.$refs.installationOrderTree as Tree).setCurrentKey(val);
    const selected = (this.$refs.installationOrderTree as Tree).getCurrentNode();
    // 若当前组件有父节点 展开其所有祖宗节点
    if (
      (this.$refs.installationOrderTree as Tree).getNode(selected) &&
      (this.$refs.installationOrderTree as Tree).getNode(selected).parent
    ) {
      this.expandParents((this.$refs.installationOrderTree as Tree).getNode(selected).parent);
    }
    this.$emit('filterSelect', selected);
  }
  public expandParents(node: any): void {
    // 展开所有祖宗节点
    node.expanded = true;
    if (node.parent) {
      this.expandParents(node.parent);
    }
  }
  private loadDetails(): void {
    installationOrderService
      .getList(
        {
          statusList: [5]
        },
        { currentPage: 1, showCount: 5000 },
        { order: 'ascending', prop: 'status' }
      )
      .then(res => {
        this.shopCityTree = this.handleTreeData(res.data as any).treeList;
        this.indexList = this.handleTreeData(res.data as any).indexList;

        this.$nextTick(async () => {
          if (!this.currentlySelectedNode) {
            this.currentlySelectedNode = this.indexList[0] as string;
          }
          this.selected(this.currentlySelectedNode);
          const node = (this.$refs.installationOrderTree as Tree).getNode(this.currentlySelectedNode);

          (this.$refs.installationOrderTree as Tree).setCurrentKey(node.key);
          this.handleNodeClick(node.data);
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
