import { ExceptionRecordList, InstallationOrderInfo, UploadConfigParams } from '@/resource/model';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { installationOrderService } from '@/api';
import { debounce, messageError } from '@/utils';
import { InstallationOrderStatusEnum } from '@/resource/enum';
import { UploadFile } from '@/views/dialogs';
import InstallationOrderPhoto from '@/views/installation-management/installation-order/installation-order-detail/installation-order-photo/installation-order-photo.vue';
import InstallAttachment from '@/views/installation-management/installation-notice/install-attachment/install-attachment.vue';
import ProductDetails from '@/views/installation-management/installation-order/installation-order-detail/product-details/product-details.vue';
import StatusStep from '@/views/installation-management/installation-order/installation-order-detail/status-step/status-step.vue';
import ExceptionRecord from '@/views/installation-management/installation-order/installation-order-detail/exception-record/exception-record.vue';
import AcceptanceRecord from '@/views/installation-management/installation-order/installation-order-detail/acceptance-record/acceptance-record.vue';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'InstallContent',
  components: {
    UploadFile,
    InstallationOrderPhoto,
    InstallAttachment,
    ProductDetails,
    StatusStep,
    ExceptionRecord,
    AcceptanceRecord
  }
})
export default class InstallContent extends Vue {
  /**
   * 安装单ID
   */

  @Prop({ required: true, default: 0, type: Number })
  public installOrderId!: number;

  public installationOrder: InstallationOrderInfo = {} as InstallationOrderInfo;

  public exceptionList: Array<ExceptionRecordList> = [];

  /**
   *  照片视屏上传管理
   */
  public uploadConfigParams: Array<UploadConfigParams> = [
    {
      name: 'unboxingVideo',
      title: '开箱视频',
      getFileMethod: installationOrderService.getUnBoxVideo,
      uploadFileMethod: installationOrderService.uploadUnBoxVideo,
      fileTypeLimit: '.mp4',
      fileSize: 20,
      fileLimit: 1,
      openDelete: false,
      openUpload: false
    },
    {
      name: 'acceptanceSignature',
      title: '验收签名',
      getFileMethod: installationOrderService.getSignPhoto,
      uploadFileMethod: installationOrderService.uploadSignPhoto,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 1,
      openDelete: false,
      openUpload: false
    },
    {
      name: 'photosBeforeInstallation',
      title: '安装前照片',
      getFileMethod: installationOrderService.getBeforePhoto,
      uploadFileMethod: installationOrderService.uploadBeforePhoto,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 20,
      openDelete: false,
      openUpload: false
    },
    {
      name: 'photosAfterInstallation',
      title: '安装后照片',
      getFileMethod: installationOrderService.getFinishPhotos,
      uploadFileMethod: installationOrderService.uploadFinishPhotos,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 50,
      openDelete: true,
      openUpload: false
    },
    {
      name: 'qualificationCertificate',
      title: '资质证照',
      getFileMethod: installationOrderService.getAptitudePhoto,
      uploadFileMethod: installationOrderService.uploadAptitudePhoto,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 5,
      openDelete: false,
      openUpload: false
    }
  ];

  /**
   * 附件上传dialog显示控制标识
   */
  public uploadDialogVisible = false;

  private needReloadList = false;

  public get address(): string {
    if (this.installationOrder?.province) {
      return `${this.installationOrder?.province}
        ${this.installationOrder?.city ? this.installationOrder?.city : ''}
        ${this.installationOrder?.district ? this.installationOrder?.district : ''}
        ${this.installationOrder?.address}`;
    }
    return '--';
  }

  public get dynamicTags(): Array<string> {
    if (this.installationOrder!.installTag) {
      return JSON.parse(this.installationOrder!.installTag) || [].map((x: any) => x.name!);
    }
    return [];
  }

  public get showApprovalBtn(): boolean {
    return this.installationOrder!.status === InstallationOrderStatusEnum.acceptancePending;
  }

  public get showPushBtn(): boolean {
    return this.installationOrder!.status === InstallationOrderStatusEnum.toBePushed;
  }

  public get showCancelBtn(): boolean {
    return [
      InstallationOrderStatusEnum.PendingOrders,
      InstallationOrderStatusEnum.pendingAppointment,
      InstallationOrderStatusEnum.inService
    ].includes(this.installationOrder!.status);
  }

  public activated(): void {
    this.needReloadList = false;
  }

  public created(): void {}

  // public beforeRouteLeave(to: Route, from: Route, next: Function): void {
  //   if (to.path === '/installation-order') {
  //     to.meta!.needReload = this.needReloadList;
  //   }
  //   next();
  // }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: InstallationOrderStatusEnum): string {
    return installationOrderService.getStatusI18Key(status);
  }

  public goBack(): void {
    this.$router.back();
  }

  @debounce()
  private getInstallationInfo(id: number): void {
    installationOrderService
      .getInfo(id)
      .then(res => {
        this.installationOrder = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getExcept(): void {
    installationOrderService
      .getExceptionList(this.installOrderId)
      .then(res => {
        this.exceptionList = res.data;
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('installOrderId')
  private gitInfo(): void {
    if (this.installOrderId > 0) {
      this.getInstallationInfo(this.installOrderId);
      this.getExcept();
    }
  }
}
